import React, { Component } from "react";
import { connect } from "react-redux";

import Order from "../../components/Order/Order";
import axios from "../../axios-orders";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../store/actions/aIndex";
import Spinner from "../../components/UI/Spinner/Spinner";

import classes from "./Orders.module.css";

class Orders extends Component {
	componentDidMount() {
		this.props.onFetchOrders(
			this.props.token,
			this.props.userId
		);
	}

	render() {
		let orders = <Spinner />;
		if (!this.props.loading) {
			orders = this.props.orders.map((order) => (
				<Order
					key={order.id}
					ingredients={order.ingredients}
					price={+order.price}
				/>
			));
		}

		return <div className={classes.Btn}>{orders}</div>;
	}
}

const mapStateToProps = (state) => ({
	loading: state.ord.loading,
	orders: state.ord.orders,
	token: state.auth.token,
	userId: state.auth.userId,
	hasOrder: state.ord.hasNewOrder,
});

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchOrders: (token, userId) =>
			dispatch(actions.fetchOrders(token, userId)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withErrorHandler(Orders, axios));
