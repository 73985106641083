import React, { Component } from "react";

import axios from "../../../axios-orders";
import { connect } from "react-redux";

import Button from "../../../components/UI/Button/Button";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Input from "../../../components/UI/Input/Input";
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import * as orderActions from "../../../store/actions/aIndex";

import classes from "./ContactData.module.css";
import { Redirect } from "react-router";

class ContactData extends Component {
	state = {
		orderForm: {
			name: {
				elType: "input",
				elConfig: {
					type: "text",
					placeholder: "Your Name",
				},
				value: "",
				validations: {
					required: true,
				},
				valid: false,
				showError: false,
			},
			address: {
				elType: "input",
				elConfig: {
					type: "text",
					placeholder: "Your Address",
				},
				value: "",
				validations: {
					required: true,
				},
				valid: false,
				showError: false,
			},
			zipCode: {
				elType: "input",
				elConfig: {
					type: "text",
					placeholder: "ZIP CODE",
				},
				value: "",
				validations: {
					required: true,
					minLength: 5,
					maxLength: 5,
				},
				valid: false,
				showError: false,
			},
			country: {
				elType: "input",
				elConfig: {
					type: "text",
					placeholder: "Country",
				},
				value: "",
				validations: {
					required: true,
				},
				valid: false,
				showError: false,
			},
			email: {
				elType: "input",
				elConfig: {
					type: "email",
					placeholder: "Email",
				},
				value: "",
				validations: {
					required: true,
				},
				valid: false,
				showError: false,
			},
			deliveryMethod: {
				elType: "select",
				elConfig: {
					options: [
						{ value: "fastest", displayValue: "Fastest" },
						{ value: "cheapest", displayValue: "Cheapest" },
					],
				},
				value: "fastest",
				validations: {
					required: false,
				},
				valid: true,
			},
		},

		// loading: false,
		// totalPrice: 0,
		// ingredients: null,
		showInvalid: false,
		formIsValid: false,
	};
	orderHandler = (event) => {
		event.preventDefault();
		this.setState({ showError: false });
		this.setState({ showInvalid: false });

		const formData = {};
		for (let formElIdentifier in this.state.orderForm) {
			formData[formElIdentifier] =
				this.state.orderForm[formElIdentifier].value;
		}

		const order = {
			ingredients: this.props.ings,
			price: this.props.price.toFixed(2),
			orderData: formData,
			userId: this.props.userId,
		};
		this.props.onOrderBurger(order, this.props.token);
	};

	checkValidity(value, rules) {
		let isValid = true;
		if (rules.required) {
			isValid = value.trim() !== "" && isValid;
		}
		if (rules.minLength) {
			isValid = value.length >= rules.minLength && isValid;
		}
		if (rules.maxLength) {
			isValid = value.length <= rules.maxLength && isValid;
		}
		return isValid;
	}

	inputChangedHandler = (event, inputIdentifier) => {
		const updatedOrderForm = {
			...this.state.orderForm,
		};
		const updatedOrderFormEl = {
			...updatedOrderForm[inputIdentifier],
		};
		updatedOrderFormEl.value = event.target.value;
		//+++  Validations
		updatedOrderFormEl.valid = this.checkValidity(
			updatedOrderFormEl.value,
			updatedOrderFormEl.validations
		);
		updatedOrderFormEl.showError = true;
		updatedOrderForm[inputIdentifier] = updatedOrderFormEl;

		let formIsValid = true;
		for (let inputIdentifier in updatedOrderForm) {
			formIsValid =
				updatedOrderForm[inputIdentifier].valid &&
				formIsValid;
		}
		formIsValid = formIsValid && this.props.price > 0;
		this.setState({
			orderForm: updatedOrderForm,
			formIsValid: formIsValid,
		});
	};

	render() {
		const formElArray = [];
		for (let key in this.state.orderForm) {
			formElArray.push({
				id: key,
				config: this.state.orderForm[key],
			});
		}
		let showAlert = this.state.showInvalid && (
			<p className={classes.Error}>
				Please correct the invalid fields!
			</p>
		);

		showAlert = +this.props.price.toFixed(2) === 0 && (
			<p className={classes.Error}>Your order is empty!</p>
		);

		let form = <Spinner />;
		let contact = <h4>Enter your Contact Data</h4>;

		let redirect = null;
		if (!this.props.loading) {
			redirect = this.props.purchased ? (
				<Redirect to='/' />
			) : null;
			form = (
				// <form className={classes.Form}>
				// <form>

				<form onSubmit={this.orderHandler}>
					{formElArray.map((formEl) => (
						<Input
							key={formEl.id}
							elType={formEl.config.elType}
							elConfig={formEl.config.elConfig}
							value={formEl.config.value}
							showError={formEl.config.showError}
							shouldValidate={formEl.config.validations}
							// invalid={!formEl.config.valid}
							invalid={
								(this.state.showInvalid ||
									formEl.config.showError) &&
								!formEl.config.valid
							}
							changed={(event) =>
								this.inputChangedHandler(event, formEl.id)
							}
						/>
					))}

					{/* <Button type="Success" clicked={this.orderHandler}> */}
					{showAlert}
					<p>
						Total price is: ${this.props.price.toFixed(2)}
					</p>
					<Button
						type='Success'
						disabled={!this.state.formIsValid}
					>
						ORDER
					</Button>
				</form>
			);
		}

		return (
			<div className={classes.ContactData}>
				{redirect}
				{contact}
				{form}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		ings: state.brg.ingredients,
		price: state.brg.totalPrice,
		purchased: state.ord.purchased,
		token: state.auth.token,
		userId: state.auth.userId,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onOrderBurger: (orderData, token) =>
			dispatch(
				orderActions.purchaseBurger(orderData, token)
			),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withErrorHandler(ContactData, axios));
