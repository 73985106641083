import * as actionTypes from "./actionTypes";
import axios from "axios";

export const logout = () => {
	localStorage.removeItem("token");
	localStorage.removeItem("expirationTime");
	localStorage.removeItem("userId");
	return {
		type: actionTypes.AUTH_LOGOUT,
	};
};
export const setAuthRedirectPath = (path) => {
	return {
		type: actionTypes.SET_AUTH_REDIRECT_PATH,
		path: path,
	};
};
export const checkAuthTimeout = (expirationTime) => {
	return (dispatch) => {
		setTimeout(() => {
			dispatch(logout());
		}, expirationTime * 1000);
	};
};
export const authStart = () => {
	return {
		type: actionTypes.AUTH_START,
	};
};
export const authSuccess = (idToken, userId) => {
	return {
		type: actionTypes.AUTH_SUCCESS,
		idToken: idToken,
		userId: userId,
	};
};
export const authFail = (error) => {
	return {
		type: actionTypes.AUTH_FAIL,
		error: error,
	};
};
export const auth = (email, password, mode) => {
	const token = "AIzaSyDxCPTojHihQhZv3JSxoS3ETXFwSUOj_m8";
	const baseURL =
		"https://identitytoolkit.googleapis.com/v1/accounts:";

	const authMode =
		mode === "signUp"
			? "signUp?key="
			: "signInWithPassword?key=";
	const authData = {
		email: email,
		password: password,
		returnSecureToken: true,
	};
	return (dispatch) => {
		dispatch(authStart());
		axios
			.post(baseURL + authMode + token, authData)
			.then((res) => {
				console.log(res);
				const expirationTime = new Date(
					new Date().getTime() + res.data.expiresIn * 1000
				);
				localStorage.setItem("token", res.data.idToken);
				localStorage.setItem("userId", res.data.localId);
				localStorage.setItem(
					"expirationTime",
					expirationTime
				);
				dispatch(
					authSuccess(res.data.idToken, res.data.localId)
				);
				dispatch(checkAuthTimeout(res.data.expiresIn));
			})
			.catch((error) => {
				console.log(error);
				dispatch(authFail(error.response.data.error));
			});
	};
};

export const authCheckState = () => {
	return (dispatch) => {
		const token = localStorage.getItem("token");
		console.log("Got the token", token);
		if (!token) {
			dispatch(logout());
		} else {
			const expirationTime = new Date(
				localStorage.getItem("expirationTime")
			);
			if (expirationTime <= new Date()) {
				dispatch(logout());
			}
			const userId = localStorage.getItem("userId");
			dispatch(authSuccess(token, userId));
			dispatch(
				checkAuthTimeout(
					(expirationTime.getTime() -
						new Date().getTime()) /
						1000
				)
			);
		}
	};
};
