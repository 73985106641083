import React from "react";

import classes from "./BuildControls.module.css";
import BuildControl from "./BuildControl/BuildControl";
// console.log(typeof classes);
const controls = [
	{ label: "Salad", type: "salad" },
	{ label: "Bacon", type: "bacon" },
	{ label: "Cheese", type: "cheese" },
	{ label: "Meat", type: "meat" },
];

const buildControls = (props) => (
	<div className={classes.BuildControls}>
		{/* Total price */}
		<div className={classes.Price}>
			<p>
				Current Price:{" "}
				<strong>${props.price.toFixed(2)}</strong>
			</p>
			<button
				disabled={props.price === 4 ? "disabled" : null}
				onClick={props.showModal}
			>
				{props.isAuth ? "ORDER NOW" : "SIGN UP TO ORDER"}
			</button>
		</div>
		{/* Total price */}
		{controls.map((ctrl) => (
			<BuildControl
				added={() => props.ingredientAdded(ctrl.type)}
				removed={() => props.ingredientRemoved(ctrl.type)}
				key={ctrl.label}
				label={ctrl.label}
				disabledInfo={props.disabledInfo[ctrl.type]}
				// My way: disable button
				ingredientCount={props.ingredients[ctrl.type]}
			/>
		))}
	</div>
);
// console.log(classes.BuildControls);
export default buildControls;
