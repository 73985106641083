import * as actionTypes from "../actions/actionTypes";

const initialState = {
	orders: [],
	loading: false,
	error: false,
	purchased: false,
	hasNewOrder: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_ORDERS:
			return {
				...state,
				orders: state.orders.concat(action.orders),
				loading: false,
			};

		case actionTypes.SET_LOADING:
			return {
				...state,
				orders: state.orders,
				loading: action.status,
			};
		case actionTypes.NEW_ORDER_ADDED:
			return {
				...state,
				hasNewOrder: false,
			};
		case actionTypes.FETCH_ORDER_ERROR:
			return {
				...state,
				error: false,
			};
		case actionTypes.PURCHASE_ORDER_START:
			return {
				...state,
				loading: true,
			};
		case actionTypes.PURCHASE_INIT:
			return {
				...state,
				purchased: false,
			};
		case actionTypes.PURCHASE_BURGER_SUCCESS:
			const newOrder = {
				...action.orderData,
				id: action.orderId,
			};
			return {
				...state,
				loading: false,
				orders: state.orders.concat(newOrder),
				purchased: true,
				hasNewOrder: true,
			};
		case actionTypes.PURCHASE_BURGER_FAIL:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};

export default reducer;
