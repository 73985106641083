export const ADD_INGREDIENT = "ADD_INGREDIENT";
export const REMOVE_INGREDIENT = "REMOVE_INGREDIENT";
export const SET_INGREDIENTS = "SET_INGREDIENTS";
export const FETCH_INGREDIENTS_FAILED =
	"FETCH_INGREDIENTS_FAILED";

export const SET_ORDERS = "SET_ORDERS";
export const FETCH_ORDER_ERROR = "FETCH_ORDER_ERROR";
export const SET_LOADING = "SET_LOADING";

export const PURCHASE_ORDER_START = "PURCHASE_ORDER_START";
export const PURCHASE_BURGER_SUCCESS =
	"PURCHASE_BURGER_SUCCESS";
export const PURCHASE_INIT = "PURCHASE_INIT";
export const PURCHASE_BURGER_FAIL = "PURCHASE_BURGER_FAIL";

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_AUTH_REDIRECT_PATH =
	"SET_AUTH_REDIRECT_PATH";

export const NEW_ORDER_ADDED = "NEW_ORDER_ADDED";
