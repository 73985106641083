import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-orders";

export const setOrders = (fetchedOrders) => {
	return {
		type: actionTypes.SET_ORDERS,
		orders: fetchedOrders,
	};
};
export const fetchOrders = (token, userId) => {
	return (dispatch) => {
		dispatch(setLoading(true));
		const queryParams =
			"?auth=" +
			token +
			'&orderBy="userId"&equalTo="' +
			userId +
			'"';
		axios
			.get("/orders.json" + queryParams)
			.then((res) => {
				const fetchedOrders = [];
				for (let key in res.data) {
					fetchedOrders.push({
						...res.data[key],
						id: key,
					});
				}
				dispatch(setOrders(fetchedOrders));
				dispatch(setLoading(false));
			})
			.catch((err) => {
				dispatch(setError(err));
				dispatch(setLoading(false));
			});
	};
};

export const setError = (error) => {
	return {
		type: actionTypes.FETCH_ORDER_ERROR,
		error: error,
	};
};
export const setLoading = (status) => {
	return { type: actionTypes.SET_LOADING };
};

export const purchaseBurgerSuccess = (id, orderData) => {
	//   console.log("id:::", id.name);
	return {
		type: actionTypes.PURCHASE_BURGER_SUCCESS,
		orderId: id,
		orderData: orderData,
	};
};

export const purchaseBurgerFail = (error) => {
	return {
		type: actionTypes.PURCHASE_BURGER_FAIL,
		error: error,
	};
};

export const purchaseInit = () => {
	return { type: actionTypes.PURCHASE_INIT };
};
export const purchaseBurgerStart = () => {
	return { type: actionTypes.PURCHASE_ORDER_START };
};

export const purchaseBurger = (orderData, token) => {
	return (dispatch) => {
		dispatch(purchaseBurgerStart());
		axios
			.post("/orders.json?auth=" + token, orderData)
			.then((res) => {
				dispatch(
					purchaseBurgerSuccess(res.data.name, orderData)
				);
			})
			.catch((error) => {
				dispatch(purchaseBurgerFail(error));
			});
	};
};
export const newOrderAdded = () => {
	return { type: actionTypes.NEW_ORDER_ADDED };
};
