import React from "react";

import Aux from "../../../hoc/Aux/Aux";
import classes from "./OrderSummary.module.css";
import Button from "../../UI/Button/Button";

const orderSummary = (props) => {
  const ingredientSummary = Object.keys(props.ingredients).map((igKey) => (
    <li key={igKey}>
      <span style={{ textTransform: "capitalize" }}>{igKey}</span>:{" "}
      {props.ingredients[igKey]}
    </li>
  ));
  return (
    <Aux>
      <div className={classes.OrderSummary}>
        <h3>Your Order</h3>
        <p>A delicious burger with the following ingredients</p>
        <ul>{ingredientSummary}</ul>
        <p>
          <strong>Total Price: ${props.price.toFixed(2)}</strong>
        </p>
        <p>Continue to Checkout?</p>
        <div className={classes.Button}>
          <Button type="Danger" clicked={props.cancel}>
            Cancel
          </Button>
          <Button type="Success" clicked={props.checkout}>
            OK
          </Button>
          {/* <button className={classes.Cancel} onClick={props.cancel}>
            Cancel
          </button>
          <button className={classes.Ok} onClick={props.checkout}>
            OK
          </button> */}
        </div>
      </div>
    </Aux>
  );
};

export default orderSummary;
