import React, { Component } from "react";
// import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Input from "../../components/UI/Input/Input";
import Spinner from "../../components/UI/Spinner/Spinner";
import Button from "../../components/UI/Button/Button";

import classes from "./Auth.module.css";
import * as actions from "../../store/actions/aIndex";
import { Redirect } from "react-router";

class Auth extends Component {
	state = {
		control: {
			email: {
				elType: "input",
				elConfig: {
					type: "email",
					placeholder: "Email",
				},
				value: "",
				validations: {
					required: true,
					isEmail: true,
				},
				valid: false,
				showError: false,
			},
			password: {
				elType: "input",
				elConfig: {
					type: "password",
					placeholder: "Password",
				},
				value: "",
				validations: {
					required: true,
					minLength: 6,
				},
				valid: false,
				showError: false,
			},
		},

		showInvalid: false,
		formIsValid: false,
		switchButton: true,
	};

	componentDidMount() {
		if (
			!this.props.buildingBurger &&
			this.props.authRedirectPath !== "/"
		) {
			this.props.onSetAuthRedirectPath();
		}
	}

	inputChangedHandler = (event, inputIdentifier) => {
		const updatedControl = {
			...this.state.control,
		};
		const updatedControlEl = {
			...updatedControl[inputIdentifier],
		};
		updatedControlEl.value = event.target.value;
		//+++  Validations
		updatedControlEl.valid = this.checkValidity(
			updatedControlEl.value,
			updatedControlEl.validations
		);
		updatedControlEl.showError = true;
		updatedControl[inputIdentifier] = updatedControlEl;

		let formIsValid = true;
		for (let inputIdentifier in updatedControl) {
			formIsValid =
				updatedControl[inputIdentifier].valid &&
				formIsValid;
		}
		// formIsValid = formIsValid && this.props.price > 0;
		this.setState({
			control: updatedControl,
			formIsValid: formIsValid,
		});
	};

	checkValidity(value, rules) {
		let isValid = true;
		if (rules.required) {
			isValid = value.trim() !== "" && isValid;
		}
		if (rules.minLength) {
			isValid = value.length >= rules.minLength && isValid;
		}
		if (rules.maxLength) {
			isValid = value.length <= rules.maxLength && isValid;
		}
		if (rules.isEmail) {
			const pattern =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			isValid = pattern.test(value) && isValid;
		}
		return isValid;
	}

	loginHandler = (event) => {
		event.preventDefault();
		const mode = this.state.switchButton
			? "signUp"
			: "signIn";
		this.props.onAuth(
			this.state.control.email.value,
			this.state.control.password.value,
			mode
		);
		console.log("Login clicked");
	};

	switchButtonHandler = (event) => {
		event.preventDefault();
		this.setState((prevState) => {
			return { switchButton: !prevState.switchButton };
		});
		console.log(this.state.switchButton);
	};

	customErrorHandler = (message) => {
		switch (message) {
			case "EMAIL_EXISTS":
				return "This account already exists!";

			default:
				return "Something went wrong";
		}
	};

	render() {
		const controlElArray = [];
		for (let key in this.state.control) {
			controlElArray.push({
				id: key,
				config: this.state.control[key],
			});
		}

		let form = (
			<form
				className={classes.Form}
				onSubmit={this.loginHandler}
			>
				{controlElArray.map((formEl) => (
					<Input
						key={formEl.id}
						elType={formEl.config.elType}
						elConfig={formEl.config.elConfig}
						value={formEl.config.value}
						showError={formEl.config.showError}
						shouldValidate={formEl.config.validations}
						// invalid={!formEl.config.valid}
						invalid={
							(this.state.showInvalid ||
								formEl.config.showError) &&
							!formEl.config.valid
						}
						changed={(event) =>
							this.inputChangedHandler(event, formEl.id)
						}
					/>
				))}

				{/* {showAlert} */}
				<div className={classes.Btn}>
					<Button
						type='Success'
						disabled={!this.state.formIsValid}
					>
						{this.state.switchButton ? "Signup" : "Login"}
					</Button>
					<Button
						clicked={this.switchButtonHandler}
						type='Toggle'
					>
						{this.state.switchButton
							? "Switch to login"
							: "Switch to signup"}
					</Button>
				</div>
			</form>
		);

		if (this.props.loading) {
			form = <Spinner />;
		}
		let errorMessage = null;
		if (this.props.error) {
			errorMessage = (
				<p className={classes.Error}>
					{this.customErrorHandler(
						this.props.error.message
					)}
				</p>
			);
		}
		const redirect = this.props.isAuth ? (
			<Redirect to={this.props.authRedirectPath} />
		) : null;
		// }
		return (
			<div>
				{redirect}
				{errorMessage}
				{form}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		loading: state.auth.loading,
		error: state.auth.error,
		isAuth: state.auth.token,
		buildingBurger: state.brg.building,
		authRedirectPath: state.auth.authRedirectPath,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAuth: (email, password, mode) =>
			dispatch(actions.auth(email, password, mode)),
		onSetAuthRedirectPath: () =>
			dispatch(actions.setAuthRedirectPath("/")),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Auth);
