import React, { Component } from "react";

import Aux from "../../../hoc/Aux/Aux";
import Backdrop from "../Backdrop/Backdrop";
import classes from "./Modal.module.css";

class Modal extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.show !== this.props.show ||
      nextProps.children !== this.props.children
    );
  }

  render() {
    return (
      <Aux>
        <Backdrop show={this.props.show} clicked={this.props.clicked} />

        <div
          onClick={this.props.clicked}
          className={this.props.show ? classes.Modal : null}
          style={
            {
              // transform: props.show ? "translateX(0)" : "translateX(-100vw)",
              //   opacity: props.show ? "1" : "0",
            }
          }
        >
          {this.props.children}
        </div>
      </Aux>
    );
  }
}

export default Modal;
