import React, { Component } from "react";
import { connect } from "react-redux";

import axios from "../../axios-orders";
import Spinner from "../../components/UI/Spinner/Spinner";

import Aux from "../../hoc/Aux/Aux";
import Burger from "../../components/Burger/Burger";
import BuildControls from "../../components/Burger/BuildControls/BuildControls";
import Modal from "../../components/UI/Modal/Modal";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";

import OrderSummary from "../../components/Burger/OrderSummary/OrderSummary";

// import * as actionTypes from "../../store/actions/actionTypes";

import * as actions from "../../store/actions/aIndex";

class BurgerBuilder extends Component {
	state = {
		// ingredients: null,
		// totalPrice: 4,
		backdropStatus: true,
		modalStatus: false,
		loading: false,
		error: false,
	};
	componentDidMount() {
		this.props.onInitIngredients();
		this.props.OnNewOrderAdded();

		// axios
		//   .get("/ingredients.json")
		//   .then((response) => {
		//     this.setState({ ingredients: response.data });
		//   })
		//   .catch((error) => {
		//     this.setState({ error: true });
		//   });
	}

	showBackdrop = () => {
		this.setState({
			backdropStatus: !this.state.backdropStatus,
		});
		// console.log(this.state.backdropStatus);
	};
	showModal = () => {
		if (this.props.isAuth) {
			this.setState({
				modalStatus: !this.state.modalStatus,
			});
		} else {
			this.props.onSetAuthRedirect("/checkout");
			this.props.history.push({
				pathname: "/authenticate",
			});
		}
		// console.log(this.state.backdropStatus);
	};

	purchaseContinueHandler = () => {
		this.props.onInitPurchase();
		this.props.history.push({
			pathname: "/checkout",
		});
	};

	render() {
		const disabledInfo = { ...this.props.ings };
		for (let ing in disabledInfo) {
			disabledInfo[ing] = disabledInfo[ing] <= 0;
		}
		let orderSummary = null;

		if (this.state.loading) {
			orderSummary = <Spinner />;
		}

		let burger = this.props.error ? (
			<p
				style={{
					textAlign: "center",
					fontSize: "2rem",
				}}
			>
				Ingredients can't be loaded
			</p>
		) : (
			<Spinner />
		);

		// if (this.state.ingredients) {
		if (this.props.ings) {
			burger = (
				<Aux>
					<Burger ingredients={this.props.ings} />
					<BuildControls
						price={this.props.price}
						// price={this.state.totalPrice}
						ingredientAdded={this.props.onIngredientAdded}
						ingredientRemoved={
							this.props.onIngredientRemoved
						}
						disabledInfo={disabledInfo}
						// My way: disable button
						ingredients={this.props.ings}
						backdrop={this.state.backdropStatus}
						showBackdrop={this.showBackdrop}
						showModal={this.showModal}
						isAuth={this.props.isAuth}
					/>
				</Aux>
			);

			orderSummary = (
				<OrderSummary
					// price={this.state.totalPrice}
					price={this.props.price}
					cancel={this.showModal}
					ingredients={this.props.ings}
					checkout={this.purchaseContinueHandler}
				></OrderSummary>
			);
		}
		return (
			<Aux>
				{this.state.modalStatus && (
					<Modal
						show={this.state.modalStatus}
						clicked={this.showModal}
					>
						{orderSummary}
					</Modal>
				)}
				{burger}
			</Aux>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		ings: state.brg.ingredients,
		price: state.brg.totalPrice,
		error: state.brg.error,
		isAuth: state.auth.token !== null,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onIngredientAdded: (ingName) =>
			dispatch(actions.addIngredient(ingName)),
		onIngredientRemoved: (ingName) =>
			dispatch(actions.removeIngredient(ingName)),
		onInitIngredients: () =>
			dispatch(actions.initIngredients()),
		onInitPurchase: () => dispatch(actions.purchaseInit()),
		OnNewOrderAdded: () =>
			dispatch(actions.newOrderAdded()),
		onSetAuthRedirect: (path) =>
			dispatch(actions.setAuthRedirectPath(path)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withErrorHandler(BurgerBuilder, axios));
