import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";

import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import {
	createStore,
	applyMiddleware,
	combineReducers,
} from "redux";
import thunk from "redux-thunk";
import burgerBuilderReducer from "./store/reducers/rBurgerBuilder";
import orderReducer from "./store/reducers/rOrder";
import authReducer from "./store/reducers/rAuth";
//! REDUX DEVTOOLS
import { composeWithDevTools } from "redux-devtools-extension";

//^ CODE
const rootReducer = combineReducers({
	brg: burgerBuilderReducer,
	ord: orderReducer,
	auth: authReducer,
});
//! MIDDLEWARE
// const logger = (store) => {
// 	return (next) => {
// 		return (action) => {
// 			console.log("[Middleware] Dispatching", action);
// 			const result = next(action);
// 			console.log(
// 				"[Middleware] next state",
// 				store.getState()
// 			);
// 			return result;
// 		};
// 	};
// };
//! LIST
const middleware = [thunk];
// const middleware = [logger, thunk];
//! REDUX DEVTOOLS SETUP
const enhancer = composeWithDevTools({
	trace: true,
	traceLimit: 25,
});

const store = createStore(
	rootReducer,
	enhancer(applyMiddleware(...middleware))
);

const app = (
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>
);

ReactDOM.render(app, document.getElementById("root"));
