import React from "react";

import Burger from "../../Burger/Burger";
import Button from "../../UI/Button/Button";
import classes from "./CheckoutSummary.module.css";
const checkoutSummary = (props) => {
  let buttons = (
    <div>
      <Button clicked={props.checkoutCancelled} type="Danger">
        Cancel
      </Button>

      {!props.checked ? (
        <Button clicked={props.checkoutContinued} type="Success">
          Checkout
        </Button>
      ) : null}
    </div>
  );

  return (
    <div className={classes.CheckoutSummary}>
      <h1>We hope it tastes well!</h1>

      <div style={{ width: "100%", margin: "auto" }}>
        <Burger ingredients={props.ingredients} />
      </div>
      {buttons}
    </div>
  );
};

export default checkoutSummary;
